import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Chip, Select, Checkbox, MenuItem, TextField, InputLabel, FormControl, OutlinedInput, FormHelperText, } from '@mui/material';
export function RHFSelect({ name, native, maxHeight = 220, helperText, children, ...other }) {
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextField, { ...field, select: true, fullWidth: true, SelectProps: {
                native,
                MenuProps: {
                    PaperProps: {
                        sx: {
                            ...(!native && {
                                px: 1,
                                maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                                '& .MuiMenuItem-root': {
                                    px: 1,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize',
                                },
                            }),
                        },
                    },
                },
                sx: { textTransform: 'capitalize' },
            }, error: !!error, helperText: error ? error?.message : helperText, ...other, children: children })) }));
}
export function RHFMultiSelect({ name, chip, label, options, checkbox, placeholder, helperText, disabledText, sx, ...other }) {
    const { control } = useFormContext();
    const renderValues = (selectedIds) => {
        const selectedItems = options.filter((item) => selectedIds.includes(item.value));
        if (!selectedItems.length && placeholder) {
            return (_jsx(Box, { component: "em", sx: { color: 'text.disabled' }, children: placeholder }));
        }
        if (chip) {
            return (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: selectedItems.map((item) => (_jsx(Chip, { size: "small", label: item.label }, item.value))) }));
        }
        return selectedItems.map((item) => item.label).join(', ');
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { sx: sx, children: [label && _jsxs(InputLabel, { id: name, children: [" ", label, " "] }), _jsxs(Select, { ...field, multiple: true, displayEmpty: !!placeholder, labelId: name, input: _jsx(OutlinedInput, { fullWidth: true, label: label, error: !!error }), renderValue: renderValues, MenuProps: {
                        PaperProps: {
                            sx: { px: 1, maxHeight: 280 },
                        },
                    }, ...other, children: [disabledText && (_jsxs(MenuItem, { disabled: true, value: "", sx: {
                                p: 0.25,
                                borderRadius: 0.75,
                                typography: 'body2',
                            }, children: [_jsx(Checkbox, { disableRipple: true, size: "small", checked: true }), disabledText] })), options.map((option) => {
                            const selected = field.value.includes(option.value);
                            return (_jsxs(MenuItem, { value: option.value, sx: {
                                    py: 1,
                                    px: 2,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    ...(selected && {
                                        fontWeight: 'fontWeightMedium',
                                    }),
                                    ...(checkbox && {
                                        p: 0.25,
                                    }),
                                }, children: [checkbox && _jsx(Checkbox, { disableRipple: true, size: "small", checked: selected }), option.label] }, option.value));
                        })] }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? error?.message : helperText }))] })) }));
}
